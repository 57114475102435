<template>
  <div class="goods">
    <van-nav-bar title="详情" left-text="返回" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="wap-home-o" size="18" @click="toHome"/>
      </template>
    </van-nav-bar>
    <van-swipe class="goods-swipe" :autoplay="3000">
      <van-swipe-item v-for="thumb in productInfo.slider_image" :key="thumb">
        <img :src="thumb">
      </van-swipe-item>
    </van-swipe>
    <van-cell-group>
      <van-cell>
        <div class="goods-title">{{ productInfo.store_name }}</div>
      </van-cell>
<!--      <van-cell class="goods-express">-->
<!--        <van-col span="14">库存：{{ productInfo.stock }}</van-col>-->
<!--      </van-cell>-->
    </van-cell-group>

    <van-divider content-position="left"
                 :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      库存
    </van-divider>

    <van-grid :column-num="2">
      <van-grid-item v-for="item in stockDataList" :key="item.sku">
        <el-statistic :title="item.sku">
          <template slot="formatter">
            <div class="van-cell__value van-cell__value--alone van-contact-list__item-value">
                <span style="margin-left: 3px;" class="stock" v-bind:class="{ 'stockWarn': item.stockWarn } ">
                      {{ item.znum }}{{ item.zunitName }}<span
                  style="font-weight: 400; font-size: 15px;">{{ item.snum }}{{ item.sunitName }}</span>
                    </span>
              <van-tag v-if="item.stockWarn" plain type="danger">缺货</van-tag>
            </div>
            <div style="font-size: 10px;">共{{ item.stock }}{{ item.sunitName }}
              每{{ item.zunitName }}{{ item.zunitNum }}{{ item.sunitName }}
            </div>
          </template>
        </el-statistic>
      </van-grid-item>
    </van-grid>

    <van-divider content-position="left"
                 :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"
    >
      销量
    </van-divider>
    <van-row>
      <van-col span="12">
        <van-dropdown-menu>
          <van-dropdown-item v-model="dateOptionValue" :options="dateOptions" @change="onChangeDateOption"/>
        </van-dropdown-menu>
      </van-col>
      <van-col span="12">
        <van-cell title="选择日期" style="height: 48px;" :value="date" @click="showCalendar = true"/>
      </van-col>
    </van-row>

    <van-grid :column-num="4">
      <van-grid-item v-for="item in statisticSlReInfo" :key="item.sku">
        <el-statistic :title="item.sku">
          <template slot="formatter">
            <span style="color: #409EFF;">{{ item.saleNum }}</span><span
            v-if="item.returnNum !== null && item.returnNum > 0">/</span><span style="color: #F56C6C;"
                                                                               v-if="item.returnNum !== null && item.returnNum > 0">{{
              item.returnNum
            }}</span>
          </template>
        </el-statistic>
      </van-grid-item>
    </van-grid>

    <div style="margin-left: 5px;">
      <el-radio-group v-model="radioSku" size="small" style="margin-top: 10px; margin-bottom: 3px;" @input="onChangeSku">
        <el-radio-button label="全部" style="margin-bottom: 2px;"></el-radio-button>
        <el-radio-button style="margin-bottom: 2px;" :label="item" v-for="item in skuList" :key="item" :name="item"></el-radio-button>
      </el-radio-group>
    </div>
    <div class="table-container" ref="tableContainer" style="padding-bottom: 30px;">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="loadData"
        >
          <div role="button" tabindex="0"
               class="van-cell el-table van-cell--clickable van-cell--center van-cell--borderless van-contact-card--edit">
            <div class="van-cell__value van-cell__value--alone van-contact-card__value">
              <van-row>
                <van-col span="8">商户</van-col>
                <van-col span="8">销量</van-col>
                <van-col span="8">价格</van-col>
              </van-row>
            </div>
          </div>
          <div role="button" tabindex="0" v-for="subItem in tableData" :key="subItem.uid" :name="subItem.uid"
               class="van-cell el-table van-cell--clickable van-cell--center van-cell--borderless van-contact-card van-contact-card--edit">
            <div class="van-cell__value van-cell__value--alone van-contact-card__value">
              <van-row>
                <van-col span="8" style="color: #606266;">{{ subItem.nickName }}</van-col>
                <van-col span="8" style="color: #606266;">{{ subItem.number }}</van-col>
                <van-col span="8" style="color: #606266;">{{ subItem.price }}</van-col>
              </van-row>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>

    <van-calendar v-model="showCalendar" type="range" :show-confirm="false" :min-date="minDate" :max-date="maxDate"
                  @confirm="onConfirm"/>
  </div>
</template>

<script>
import {
  Calendar,
  ContactList,
  Divider,
  DropdownItem,
  DropdownMenu,
  Grid,
  GridItem,
  Image,
  List,
  NavBar,
  Popup,
  PullRefresh,
  Swipe,
  SwipeItem,
  Tag,
} from 'vant';
import * as productService from "@/api/admin/product";
import * as analysisService from "@/api/admin/analysis";
import dayjs from "dayjs";
import * as adminPriceService from "@/api/admin/price";

export default {
  components: {
    [Tag.name]: Tag,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
    [Image.name]: Image,
    [Divider.name]: Divider,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Calendar.name]: Calendar,
    [NavBar.name]: NavBar,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [ContactList.name]: ContactList,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },

  data() {
    return {
      productInfo: {},
      statisticSlReInfo: [],
      stockDataList: [],
      date: '',
      show: false,
      showCalendar: false,
      minDate: new Date(2010, 0, 1),
      maxDate: new Date(2030, 0, 31),
      value1: 0,
      dateOptionValue: '0',
      dateOptions: [
        {text: '自选时间', value: '-1'},
        {text: '去年', value: '0'},
        {text: '今年', value: '1'},
        {text: '前年', value: '2'},
      ],
      tableData: [],
      pageSize: 10, // 每页数据量
      loading: false, // 加载状态
      finished: false,
      refreshing: false,
      pages: {
        perPage: 0,
        currPage: 1,
        pageCount: 0
      },
      startDate: null,
      end: null,
      radioSku: '全部',
      skuList: []
    };
  },
  mounted() {
  },
  created() {
    if (this.$route.params && this.$route.params.productId) {
      productService.getProductInfo(this.$route.params.productId).then(res => {
        console.log(res)
        this.productInfo = res.productInfo
        this.skuList = res.skuList
      }).catch(error => {
      });
      const param = {
        productId: this.$route.params.productId,
        dateType: this.dateOptionValue,
      }
      this.getStatisticSaleReturn(param)
      this.getWholesaleProductStockByProduct(this.$route.params.productId)
    }
  },
  methods: {
    // 加载数据
    loadData() {
      console.log('onload')
      if (this.refreshing) {
        this.tableData = [];
        this.refreshing = false;
      }
      let searchParam = {
        productId: this.$route.params.productId,
        sku: this.radioSku,
        dateType: this.dateOptionValue,
        startDate: this.startDate,
        endDate: this.endDate,
        page: this.pages.currPage,
        limit: this.pageSize
      }
      if (this.radioSku === '全部') {
        searchParam.sku = null
      }

      let that = this
      analysisService.getSaleSumNum(searchParam).then(res => {
        if (res.status === 200) {
          console.log(res)
          that.tableData = this.tableData.concat(res.data.records);
          that.loading = false;
          that.pages.currPage++;
          that.pages.pageCount = res.data.pages;
          if (that.pages.currPage > that.pages.pageCount) {
            that.finished = true;
          }
        }
      }).catch(error => {
      })
    },
    onRefresh() {
      // 清空列表数据
      this.finished = false;
      this.tableData = [];
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      this.loading = true;
      this.pages.currPage = 1;
      console.log(this.pages)
      this.loadData();
    },
    getStatisticSaleReturn(param) {
      analysisService.getStatisticSaleReturn(param).then(res => {
        if (res.status === 200) {
          console.log(res)
          this.statisticSlReInfo = res.data
        }
      }).catch(error => {
      })
    },
    getWholesaleProductStockByProduct(productId) {
      let param = {
        // stockNotEnough: this.stockNotEnoughSwitchChecked,
        // tag: this.$route.params.tag
      }
      adminPriceService.getWholesaleProductStockByProduct(productId, param).then(res => {
        if (res.status === 200) {
          this.stockDataList = res.data
        }
      })
    },
    toHome() {
      this.$router.push({path: '/index'})
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    onConfirm(date) {
      this.dateOptionValue = '-1'
      const [start, end] = date
      this.showCalendar = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      const param = {
        productId: this.$route.params.productId,
        startDate: dayjs(start).format('YYYY-MM-DD HH:mm:ss'),
        endDate: dayjs(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      }
      this.startDate = dayjs(start).format('YYYY-MM-DD HH:mm:ss')
      this.endDate = dayjs(end).endOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.getStatisticSaleReturn(param)
      this.onRefresh()
    },
    formatDate(date) {
      return `${date.getMonth() + 1}/${date.getDate()}`
    },
    onChangeDateOption() {
      this.date = ''
      this.startDate = null
      this.endDate = null
      const param = {
        productId: this.$route.params.productId,
        dateType: this.dateOptionValue,
      }
      this.getStatisticSaleReturn(param)
      this.onRefresh()
    },
    onChangeSku(label) {
      console.log(label)
      this.onRefresh()
    }
  },
  beforeDestroy: function () {
  }
};
</script>

<style lang="less">
.table-container {
  height: 300px; /* 固定高度 */
  overflow: auto; /* 禁用原生滚动 */
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  line-height: 150px;
  text-align: center;
  background-color: #39a9ed;
}

.goods {
  padding-bottom: 50px;

  &-swipe {
    img {
      width: 100%;
      display: block;
    }
  }

  &-title {
    font-size: 16px;
  }

  &-price {
    color: #f44;
  }

  &-express {
    color: #999;
    font-size: 12px;
    padding: 5px 15px;
  }

  &-cell-group {
    margin: 15px 0;

    .van-cell__value {
      color: #999;
    }
  }

  &-tag {
    margin-left: 5px;
  }
}
</style>
