import request from '@/utils/admin/request'

export function getCreditList(param) {
    return request({
        url: '/charge-analysis/credit-list',
        method: 'get',
        params: param
    });
}

export function getStatisticSaleReturn(param) {
    return request({
        url: '/c/product/statistic-sale-return',
        method: 'get',
        params: param
    });
}

export function getSaleSumNum(param) {
    return request({
        url: '/charge-analysis/page-sale-sum-num',
        method: 'get',
        params: param
    });
}

